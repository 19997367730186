import React, { useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Box, Grid, Container, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { Icon } from '@iconify/react'
import linkedinIcon from '@iconify/icons-logos/linkedin-icon'
import githubIcon from '@iconify/icons-logos/github'
import twitterIcon from '@iconify/icons-logos/twitter'
import { Analytics } from '@vercel/analytics/react'
import './styles.css'
import { App } from './App'
import SlidingText from './slidingtext'
import Bottomslider from './bottomslider'
import About from './pages/about'
import LinksSection from './components/LinksSection'
import Skills from './components/Skills'
import Interests from './components/Interests'

const isNotMobile = window.screen.width > 700

function Root() {
  const iframeStyle = useMemo(() => ({
    border: "1px solid #777",
    maxWidth: isNotMobile ? "750px" : "85%",
    width: !isNotMobile ? "85%" : "100%",
    display: "block",
    margin: "0 auto",
  }), [])

  return (
    <div style={{ 
      position: 'relative', 
      display: 'flex', 
      flexDirection: 'column' 
    }}>
      {/* First section with reduced spacing */}
      <div style={{ minHeight: '80vh' }}>
        <SlidingText />
        <div className="appRender" 
          style={{ 
            width: "100vw", 
            height: "50vh", 
            minHeight: "400px", 
            marginBottom: "2vh" 
          }}>
          <Analytics />
          <App />
        </div>
        <Bottomslider />
      </div>

      {/* Portfolio section */}
      <motion.div
        className="Portfolio"
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ type: 'Spring', stiffness: 50, delay: 0.5, duration: 1 }}
        style={{ width: '100%', marginTop: '2vh' }}
      >
        <Box sx={{ flexGrow: 1, padding: isNotMobile ? '2rem' : '1rem' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <iframe
                src="https://indd.adobe.com/embed/c4eb285c-7b4f-49ee-bc38-5926bed0d74d?startpage=1&allowFullscreen=true"
                title="Portfolio"
                width="100%"
                height={isNotMobile ? "371px" : "300px"}
                style={iframeStyle}
                frameBorder="0"
                allowFullScreen
              />
            </Grid>
          </Grid>
        </Box>
      </motion.div>

      {/* Additional content section */}
      <div>
        <Box sx={{ flexGrow: 1, padding: isNotMobile ? '2rem' : '1rem' }}>
          <Grid container spacing={2} sx={{ minHeight: '1000px' }}>

            {/* Left column - LinksSection */}
            <Grid item xs={12} md={6} sx={{ height: '100%', display:'flex' }}>
              <Box sx={{width: '100%', height:'100%'}}>
              <LinksSection />
              </Box>
            </Grid>
            
            {/* Right column - About and Skills */}
            <Grid item xs={12} md={6} sx={{ height: '100%' }}>
              <Grid 
                container 
                direction="column" 
                spacing={2}
                sx={{ height: '100%' }}
              >
                <Grid item xs={12} sx={{ height: '33' }}>
                  <About
                    resumeBasicInfo={{
                      description_header: "Hi",
                      description: "Hi—I'm Alejandro Hernandez. Extroverted, enthusiastic, and friendly, I study both Pure Mathematics and Computer Science (first love still is physics!) with nearly a decade of combined work experience in software development and IT. Bilingual (Spanish), confident and experienced in public speaking. My personal philosophy seeks compassionate/constructive solutions to critical issues with clients. Detail-oriented writer. Autodidact concurrently enthusiastic toward learning new skills from others. When not working, can often be found either surfing and cycling at the beach!",
                      section_name: {
                        about: "About me"
                      }
                    }}
                    sharedBasicInfo={{
                      image: "null"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ height: '33%' }}>
                  <Skills skills="Self-taught in derivatives trading, financial mathematical methods (dynamic hedging) using brokerage platforms (notably the TDAmeritrade API). International Data Engineering & Science Association member (attendee 2017-pres- ent). Highly adept in C/C++, Excel, Python, R, MATLAB, Java, LaTeX, JS/TS, HTML5, HTTP/HTTPS, REST API creation/ management. Big fan of GCP + Firebase. Hackathon addict (I enjoy collaborating and learning from others). Working proficiency in Visual Basic, Wolfram Language, SQL. Competent in Azure, AWS, DO solution deployment for enter- prise, as well as CRM software (i.e. ERPNext, Salesforce) and Tableau." />
                </Grid>
                <Grid item xs={12} sx={{height: '33%'}}>
                  <Interests interests="I am deeply passionate about the development of autonomous systems and robotics, with a particular focus on real-time distributed architectures and safety-critical applications. Through my work leading Formula SAE's autonomous racing platform, I've developed extensive experience in sensor fusion, state estimation, and autonomous navigation systems. I find myself constantly drawn to the challenges of optimizing embedded systems and developing robust control architectures that can operate reliably in complex environments. My research experience in the Barsukov Group has strengthened my interest in high-precision data acquisition and signal processing, while my work on simulation frameworks has shown me the crucial role of comprehensive testing methodologies in autonomous system development. I'm particularly fascinated by the integration of AI/ML applications in robotics and the optimization of edge computing solutions for real-time operations. Currently, I'm focused on advancing autonomous navigation systems, optimizing distributed computing architectures, and developing novel simulation-based validation methodologies. My ultimate goal is to contribute to the development of robust, safe autonomous systems that can operate effectively in challenging real-world environments. I believe in combining rigorous engineering principles with innovative approaches to push the boundaries of what's possible in autonomous systems and robotics." />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Social Links */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ marginTop: 4, marginBottom: 4, padding: '2rem' }}
        >
          <a href="https://www.linkedin.com/in/hernan-tech" target="_blank" rel="noopener noreferrer">
            <Icon icon={linkedinIcon} width="32" height="32" />
          </a>
          <a href="https://github.com/hernantech" target="_blank" rel="noopener noreferrer">
            <Icon icon={githubIcon} width="32" height="32" />
          </a>
          <a href="https://twitter.com/cokeandmath" target="_blank" rel="noopener noreferrer">
            <Icon icon={twitterIcon} width="32" height="32" />
          </a>
        </Stack>
      </div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <BrowserRouter><Root /></BrowserRouter>
)