// App.js
import * as THREE from 'three'
import React, { useState, useEffect } from 'react';
import { useRef, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Image, Environment, ScrollControls, useScroll, useTexture, useProgress } from '@react-three/drei'
import { easing } from 'maath'
import './util'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import LoadingScreen from './LoadingScreen'
import useRealLoading from './useLoading'

// Preload component that ensures all textures are loaded
function ScenePreloader({ onComplete }) {
  const textures = useTexture([
    '/website_banner.png',
    '/img1_.jpg',
    '/img2_.jpg',
    '/img3_.jpg',
    '/img4_.jpg',
    '/img5_.jpg',
    '/img6_.jpg',
    '/img7_.jpg',
    '/img8_.jpg',
    '/img9_.jpg',
    '/img10_.jpg'
  ]);

  useEffect(() => {
    if (textures.every(texture => texture.image)) {
      onComplete();
    }
  }, [textures, onComplete]);

  return null;
}

export const App = () => {
  const navigate = useNavigate();
  const { progress, isLoading, setThreeReady } = useRealLoading();
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [sceneReady, setSceneReady] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;
      setScrollPercentage(scrollPercentage);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const iframe = document.querySelector('.Portfolio iframe');
    if (iframe) {
      if (scrollPercentage > 33) {
        iframe.classList.add('visible');
      } else {
        iframe.classList.remove('visible');
      }
    }
  }, [scrollPercentage]);

  const handleSceneLoad = () => {
    setSceneReady(true);
    setThreeReady(true);
  };

  return (
    <>
      <AnimatePresence>
        {(isLoading || !sceneReady) && <LoadingScreen progress={progress} />}
      </AnimatePresence>
      
      <Canvas camera={{ position: [0, 0, 100], fov: 20 }}>
        <fog attach="fog" args={['#D3D3D3', 8.5, 12]} />
        <Suspense fallback={null}>
          <ScenePreloader onComplete={handleSceneLoad} />
          {sceneReady && (
            <>
              <ScrollControls pages={4} infinite>
                <Rig rotation={[0, 0, 0.15]}>
                  <Carousel />
                </Rig>
                <Banner position={[0, -0.15, 0]} />
              </ScrollControls>
              <Environment preset="city" background blur={0.3} />
            </>
          )}
        </Suspense>
      </Canvas>
    </>
  );
};

function Rig(props) {
  const ref = useRef()
  const scroll = useScroll()
  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2)
    state.events.update()
    easing.damp3(state.camera.position, [-state.pointer.x * 2, state.pointer.y + 1.5, 10], 0.3, delta)
    state.camera.lookAt(0, 0, 0)
  })
  return <group ref={ref} {...props} />
}

function Carousel({ radius = 2.0, count = 9 }) {
  return Array.from({ length: count }, (_, i) => (
    <Card
      key={i}
      url={`/img${Math.floor(i % 10) + 1}_.jpg`}
      position={[
        Math.sin((i / count) * Math.PI * 2) * radius,
        0,
        Math.cos((i / count) * Math.PI * 2) * radius
      ]}
      rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
    />
  ))
}

function Card({ url, ...props }) {
  const ref = useRef()
  const [hovered, hover] = useState(false)
  const pointerOver = (e) => (e.stopPropagation(), hover(true))
  const pointerOut = () => hover(false)
  
  useFrame((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? 1.15 : 1, 0.1, delta)
    easing.damp(ref.current.material, 'radius', hovered ? 0.25 : 0.1, 0.2, delta)
    easing.damp(ref.current.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta)
  })
  
  return (
    <Image
      ref={ref}
      url={url}
      transparent
      side={THREE.DoubleSide}
      onPointerOver={pointerOver}
      onPointerOut={pointerOut}
      {...props}
    >
      <bentPlaneGeometry args={[0.1, 1, 1, 20, 20]} />
    </Image>
  )
}

function Banner(props) {
  const ref = useRef()
  const texture = useTexture('/website_banner.png')
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping
  const scroll = useScroll()
  
  useFrame((state, delta) => {
    ref.current.material.time.value += Math.abs(scroll.delta) * 4
    ref.current.material.map.offset.x += delta / 8
  })
  
  return (
    <mesh ref={ref} {...props}>
      <cylinderGeometry args={[2.6, 2.8, 0.20, 64, 4, true]} />
      <meshSineMaterial
        map={texture}
        map-anisotropy={64}
        map-repeat={[3, 1]}
        side={THREE.DoubleSide}
        toneMapped={false}
      />
    </mesh>
  )
}