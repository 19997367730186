import React, { useState, useEffect } from 'react'
import { Box, Typography, Paper } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon } from '@iconify/react'
import githubIcon from '@iconify/icons-logos/github'

const LinksSection = () => {
  const [imageIndices, setImageIndices] = useState({
    foundersInc: 0,
    ieeelecture: 0
  });

  const links = [
    {
      id: 'foundersInc',
      title: 'Founders Inc. AI Hackathon',
      url: 'https://github.com/hernantech/finc-hackathon-repo',
      description: 'Computer-Vision only AI-powered turtlebot (we removed the LIDAR sensor) built with the aim of helping blind individuals. Can converse, identify objects, navigate autonomously towards objects, and retrieve them.',
      images: [
        '/7D0E3719-1A76-4F24-B3BA-3B58C626DD4C_1_102_o.jpeg',
        '/hackathon_group.jpeg'
      ],
      offset: 0, // No offset for first link
      github: 'https://github.com/hernantech/finc-hackathon-repo'
    },
    {
      title: 'EcoCar UCR',
      url: 'https://ecocar.engr.ucr.edu/subteams#connected-and-automated-vehicl',
      description: 'Member of the Connected and Autonomous Vehicle team. Duties Summer/Fall 2024 include LIDAR Sensor Fusion and assisting in CI/CD pipelines (Github actions, Gitlab Runners, MATLAB, etc)',
      videoUrl: '/18023996912421685.mp4', //putting video URL here
      github: 'https://github.com/XuanpengZhao/EcoCAR-CAV'
    },
    {
      id: 'ieeelecture',
      title: 'IEEE Lecture',
      url: 'https://www.overleaf.com/read/ftbkyfgvtnxk#1b8fd2',
      description: 'Technical presentation on firmware validation methodologies and simulation frameworks. Demonstrated approaches for achieving 90% reduction in prototyping costs through sim-based validation, featuring Renode simulation for multi-node system validation and PX4-AirSim bridge implementation.\n',
      images: ['/ieeelecture.png', '/python_bridge.png'],
      offset: 2500,
      github: 'https://github.com/hernantech'
    },
    {
      title: 'Galois Research',
      url: 'https://galoisresearch.com',
      description: 'Developing a unified hardware and software stack for OS-free distributed edge computing and autonomous agents. Primarily focused on developing dual-use LTCN/CNN based AI models and supporting toolchains. Working towards EW+drone use cases.',
      image: '/galois.jpeg',
      github: 'https://github.com/Galois-Research'
    }
  ]

  useEffect(() => {
    const intervals = links
      .filter(link => link.images && link.images.length > 1)
      .map(link => {
        // Wait for the offset before starting the interval
        const timeout = setTimeout(() => {
          const interval = setInterval(() => {
            setImageIndices(prev => ({
              ...prev,
              [link.id]: prev[link.id] === 0 ? 1 : 0
            }));
          }, 5000); // 5 second swap interval

          // Clean up interval on unmount
          return () => clearInterval(interval);
        }, link.offset);

        // Clean up timeout on unmount
        return () => clearTimeout(timeout);
      });

    // Clean up all intervals on unmount
    return () => intervals.forEach(cleanup => cleanup?.());
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  }

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5, ease: "easeOut" } }
  }

  const imageVariants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 }
  }

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, backgroundColor: '#fff', p: 3, height: '100%', display:'flex', flexDirection:'column' }}>
      <Typography variant="h4" gutterBottom>Additional Featured Projects</Typography>
      <Box>
        <motion.div variants={containerVariants} initial="hidden" animate="visible">
          {links.map((link, index) => (
            <motion.div key={index} variants={itemVariants}>
              <Box sx={{
                mb: index !== links.length - 1 ? 3 : 0,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                backgroundColor: 'rgba(0,0,0,0.02)',
                borderRadius: 1,
                overflow: 'hidden'
              }}>
                {/* Media Container */}
                <Box sx={{
                  position: 'relative',
                  width: { xs: '100%', sm: '160px' },
                  minWidth: { xs: 'auto', sm: '160px' },
                  height: { xs: '160px', sm: '160px' }
                }}>
                  {link.videoUrl ? (
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block'
                      }}
                    >
                      <source src={link.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : link.images ? (
                    <AnimatePresence mode="wait">
                      <motion.img
                        key={imageIndices[link.id]}
                        src={link.images[imageIndices[link.id]]}
                        alt={link.title}
                        variants={imageVariants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          display: 'block',
                          position: 'absolute'
                        }}
                      />
                    </AnimatePresence>
                  ) : (
                    <motion.img
                      whileHover={{ scale: 1.05 }}
                      src={link.image}
                      alt={link.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block'
                      }}
                    />
                  )}
                </Box>
                {/* Content Container */}
                <Box sx={{
                  p: 2,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Box>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}>
                      <Typography
                        variant="h6"
                        component="a"
                        href={link.url}
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'none',
                          '&:hover': { textDecoration: 'underline' }
                        }}
                      >
                        {link.title}
                      </Typography>
                      <motion.a
                        href={link.github}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Icon icon={githubIcon} style={{ fontSize: '24px', cursor: 'pointer' }} />
                      </motion.a>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {link.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          ))}
        </motion.div>
      </Box>
    </Paper>
  )
}

export default LinksSection